require("./bootstrap");

const aes = window.aes = require('crypto-js/aes');
const encHex = window.encHex = require('crypto-js/enc-hex');
const padZeroPadding = window.padZeroPadding = require('crypto-js/pad-zeropadding');


function checkMaxLength(textareaID, maxLength) {

    currentLengthInTextarea = $("#" + textareaID).val().length;

    $('span #').text(parseInt(maxLength) - parseInt(currentLengthInTextarea));

    if (currentLengthInTextarea > (maxLength)) {

        // Trim the field current length over the maxlength.
        $("textarea#comment").val($("textarea#comment").val().slice(0, maxLength));
        $(remainingLengthTempId).text(0);

    }
}

function cleanElementForm(element) {
    let tagName = element.prop('tagName')
    let type = element.attr('type')

    if (tagName == 'INPUT' || tagName == 'TEXTAREA') {
        if (type == 'checkbox' || type == 'radio') {
            element.prop('checked', false);
        } else if (type == 'file') {
            $("#listFiles_"+element.attr('id')).html('');                        
        } else {
            element.val('')
        }
    } else if (tagName == 'SELECT') {
        element.val('').trigger("change")
    }
}

$(document).ready(function () {

    $('code').each(function (i, block) {
        hljs.highlightBlock(block);
    });

    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl)
    })

    $(".destroyRecord").on('click', function (obj) {
        const link = obj.target
        const jqLink = $(link)

        Swal.fire({
            title: "Deseja excluir este registro?",
            text: "Esta ação, se confirmada, não poderá ser revogada.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#000000",
            cancelButtonColor: "#53595F",
            confirmButtonText: "Confirmar",
            cancelButtonText: "Cancelar"
        }).then((result) => {
            if (result.isConfirmed) {
                jqLink.closest('form').submit();
                $("#loading-overlay").show();
            }
        })
    });

    $(".linkCreateFormVersion").on('click', function (obj) {
        const link = obj.target
        const confirmLink = $(link)

        Swal.fire({
            title: "Deseja realmente criar uma nova versão deste formulário?",
            text: "A nova versão será criada para realização de alterações, porém ficará vetada para utilização até que seja elevada à versão oficial.",
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#0B5ED7",
            cancelButtonColor: "#5C636A",
            confirmButtonText: "Confirmar",
            cancelButtonText: "Cancelar"
        }).then((result) => {
            if (result.isConfirmed) {
                $("#loading-overlay").show();
                window.location.href = confirmLink.data('route');                
            }
        })
    });

    $(".linkCloseFormVersion").on('click', function (obj) {
        const link = obj.target
        const confirmLink = $(link)

        Swal.fire({
            title: "Deseja realmente oficializar esta versão do formulário?",
            text: "Caso confirme a operação, esta versão será trancada definitivamente para alterações e se tornará a versão oficial do formulário.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#0B5ED7",
            cancelButtonColor: "#5C636A",
            confirmButtonText: "Confirmar",
            cancelButtonText: "Cancelar"
        }).then((result) => {
            if (result.isConfirmed) {
                $("#loading-overlay").show();
                window.location.href = confirmLink.data('route');                
            }
        })
    });

    $(".linkSubmitGetRoute").on('click', function () {                
        
        const confirmLink = $(this)

        Swal.fire({
            title: confirmLink.data('swalTitle'),
            text: confirmLink.data('swalText'),
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#0B5ED7",
            cancelButtonColor: "#5C636A",
            confirmButtonText: "Confirmar",
            cancelButtonText: "Cancelar"
        }).then((result) => {
            if (result.isConfirmed) {
                $("#loading-overlay").show();
                window.location.href = confirmLink.data('route');                
            }
        })
    });

    $('.datatable').DataTable({
        "language": {
            "emptyTable": "Nenhum registro encontrado",
            "info": "Mostrando de _START_ até _END_ de _TOTAL_ registros",
            "infoEmpty": "Mostrando 0 até 0 de 0 registros",
            "infoFiltered": "(Filtrados de _MAX_ registros)",
            "infoThousands": ".",
            "loadingRecords": "Carregando...",
            "processing": "Processando...",
            "zeroRecords": "Nenhum registro encontrado",
            "search": "Pesquisar",
            "paginate": {
                "next": "Próximo",
                "previous": "Anterior",
                "first": "Primeiro",
                "last": "Último"
            },
            "aria": {
                "sortAscending": ": Ordenar colunas de forma ascendente",
                "sortDescending": ": Ordenar colunas de forma descendente"
            },
            "select": {
                "rows": {
                    "_": "Selecionado %d linhas",
                    "1": "Selecionado 1 linha"
                },
                "cells": {
                    "1": "1 célula selecionada",
                    "_": "%d células selecionadas"
                },
                "columns": {
                    "1": "1 coluna selecionada",
                    "_": "%d colunas selecionadas"
                }
            },
            "buttons": {
                "copySuccess": {
                    "1": "Uma linha copiada com sucesso",
                    "_": "%d linhas copiadas com sucesso"
                },
                "collection": "Coleção  <span class=\"ui-button-icon-primary ui-icon ui-icon-triangle-1-s\"><\/span>",
                "colvis": "Visibilidade da Coluna",
                "colvisRestore": "Restaurar Visibilidade",
                "copy": "Copiar",
                "copyKeys": "Pressione ctrl ou u2318 + C para copiar os dados da tabela para a área de transferência do sistema. Para cancelar, clique nesta mensagem ou pressione Esc..",
                "copyTitle": "Copiar para a Área de Transferência",
                "csv": "CSV",
                "excel": "Excel",
                "pageLength": {
                    "-1": "Mostrar todos os registros",
                    "_": "Mostrar %d registros"
                },
                "pdf": "PDF",
                "print": "Imprimir",
                "createState": "Criar estado",
                "removeAllStates": "Remover todos os estados",
                "removeState": "Remover",
                "renameState": "Renomear",
                "savedStates": "Estados salvos",
                "stateRestore": "Estado %d",
                "updateState": "Atualizar"
            },
            "autoFill": {
                "cancel": "Cancelar",
                "fill": "Preencher todas as células com",
                "fillHorizontal": "Preencher células horizontalmente",
                "fillVertical": "Preencher células verticalmente"
            },
            "lengthMenu": "Exibir _MENU_ resultados por página",
            "searchBuilder": {
                "add": "Adicionar Condição",
                "button": {
                    "0": "Construtor de Pesquisa",
                    "_": "Construtor de Pesquisa (%d)"
                },
                "clearAll": "Limpar Tudo",
                "condition": "Condição",
                "conditions": {
                    "date": {
                        "after": "Depois",
                        "before": "Antes",
                        "between": "Entre",
                        "empty": "Vazio",
                        "equals": "Igual",
                        "not": "Não",
                        "notBetween": "Não Entre",
                        "notEmpty": "Não Vazio"
                    },
                    "number": {
                        "between": "Entre",
                        "empty": "Vazio",
                        "equals": "Igual",
                        "gt": "Maior Que",
                        "gte": "Maior ou Igual a",
                        "lt": "Menor Que",
                        "lte": "Menor ou Igual a",
                        "not": "Não",
                        "notBetween": "Não Entre",
                        "notEmpty": "Não Vazio"
                    },
                    "string": {
                        "contains": "Contém",
                        "empty": "Vazio",
                        "endsWith": "Termina Com",
                        "equals": "Igual",
                        "not": "Não",
                        "notEmpty": "Não Vazio",
                        "startsWith": "Começa Com",
                        "notContains": "Não contém",
                        "notStarts": "Não começa com",
                        "notEnds": "Não termina com"
                    },
                    "array": {
                        "contains": "Contém",
                        "empty": "Vazio",
                        "equals": "Igual à",
                        "not": "Não",
                        "notEmpty": "Não vazio",
                        "without": "Não possui"
                    }
                },
                "data": "Data",
                "deleteTitle": "Excluir regra de filtragem",
                "logicAnd": "E",
                "logicOr": "Ou",
                "title": {
                    "0": "Construtor de Pesquisa",
                    "_": "Construtor de Pesquisa (%d)"
                },
                "value": "Valor",
                "leftTitle": "Critérios Externos",
                "rightTitle": "Critérios Internos"
            },
            "searchPanes": {
                "clearMessage": "Limpar Tudo",
                "collapse": {
                    "0": "Painéis de Pesquisa",
                    "_": "Painéis de Pesquisa (%d)"
                },
                "count": "{total}",
                "countFiltered": "{shown} ({total})",
                "emptyPanes": "Nenhum Painel de Pesquisa",
                "loadMessage": "Carregando Painéis de Pesquisa...",
                "title": "Filtros Ativos",
                "showMessage": "Mostrar todos",
                "collapseMessage": "Fechar todos"
            },
            "thousands": ".",
            "datetime": {
                "previous": "Anterior",
                "next": "Próximo",
                "hours": "Hora",
                "minutes": "Minuto",
                "seconds": "Segundo",
                "amPm": [
                    "am",
                    "pm"
                ],
                "unknown": "-",
                "months": {
                    "0": "Janeiro",
                    "1": "Fevereiro",
                    "10": "Novembro",
                    "11": "Dezembro",
                    "2": "Março",
                    "3": "Abril",
                    "4": "Maio",
                    "5": "Junho",
                    "6": "Julho",
                    "7": "Agosto",
                    "8": "Setembro",
                    "9": "Outubro"
                },
                "weekdays": [
                    "Domingo",
                    "Segunda-feira",
                    "Terça-feira",
                    "Quarta-feira",
                    "Quinte-feira",
                    "Sexta-feira",
                    "Sábado"
                ]
            },
            "editor": {
                "close": "Fechar",
                "create": {
                    "button": "Novo",
                    "submit": "Criar",
                    "title": "Criar novo registro"
                },
                "edit": {
                    "button": "Editar",
                    "submit": "Atualizar",
                    "title": "Editar registro"
                },
                "error": {
                    "system": "Ocorreu um erro no sistema (<a target=\"\\\" rel=\"nofollow\" href=\"\\\">Mais informações<\/a>)."
                },
                "multi": {
                    "noMulti": "Essa entrada pode ser editada individualmente, mas não como parte do grupo",
                    "restore": "Desfazer alterações",
                    "title": "Multiplos valores",
                    "info": "Os itens selecionados contêm valores diferentes para esta entrada. Para editar e definir todos os itens para esta entrada com o mesmo valor, clique ou toque aqui, caso contrário, eles manterão seus valores individuais."
                },
                "remove": {
                    "button": "Remover",
                    "confirm": {
                        "_": "Tem certeza que quer deletar %d linhas?",
                        "1": "Tem certeza que quer deletar 1 linha?"
                    },
                    "submit": "Remover",
                    "title": "Remover registro"
                }
            },
            "decimal": ",",
            "stateRestore": {
                "creationModal": {
                    "button": "Criar",
                    "columns": {
                        "search": "Busca de colunas",
                        "visible": "Visibilidade da coluna"
                    },
                    "name": "Nome:",
                    "order": "Ordernar",
                    "paging": "Paginação",
                    "scroller": "Posição da barra de rolagem",
                    "search": "Busca",
                    "searchBuilder": "Mecanismo de busca",
                    "select": "Selecionar",
                    "title": "Criar novo estado",
                    "toggleLabel": "Inclui:"
                },
                "duplicateError": "Já existe um estado com esse nome",
                "emptyError": "Não pode ser vazio",
                "emptyStates": "Nenhum estado salvo",
                "removeConfirm": "Confirma remover %s?",
                "removeError": "Falha ao remover estado",
                "removeJoiner": "e",
                "removeSubmit": "Remover",
                "removeTitle": "Remover estado",
                "renameButton": "Renomear",
                "renameLabel": "Novo nome para %s:",
                "renameTitle": "Renomear estado"
            }
        }
    });


    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
        return new bootstrap.Popover(popoverTriggerEl)
    })

    $('#back-to-top').hide()
        .css('opacity', '0.4');

    $(window).on('scroll', function () {
        if ($(this).scrollTop() > 100) {
            $('#back-to-top').fadeIn();
        } else {
            $('#back-to-top').fadeOut();
        }
    });

    $("#back-to-top").on('click', function () {
        window.scrollTo(0, 0);
    })

    $("#back-to-top").hover(function () {
        $(this).css('opacity', '1');
    })

    $("#back-to-top").on('mouseout', function () {
        $(this).css('opacity', '0.4');
    })


    // Botão print-page
    $('#print-page').css('opacity', '0.4');

    $("#print-page").on('click', function () {
        window.print();
    })

    $("#print-page").hover(function () {
        $(this).css('opacity', '1');
    })

    $("#print-page").on('mouseout', function () {
        $(this).css('opacity', '0.4');
    })


    $("#bodyContainer").delay(30).fadeIn(500);

    $(".telefone").mask("(00) 00000-0000", {
        placeholder: "( ) _____-____"
    });

    $(".cep").mask("00000-000", {
        placeholder: "_____-___",
    });

    $(".cpf").mask("000.000.000-00", {
        reverse: true,
        placeholder: "___.___.___-__",
    });

    $(".valor-monetario").mask("#.###.###.###.##0,00", {
        reverse: true
    });

    //$('.number').mask('000000000000000000000000000000', { reverse: false });

    $('.number').bind('keydown', function (e) {
        const key = (window.event) ? e.keyCode : e.which;
        const field = $(this)
        const value = field.val()
        let maxLength = field.attr('max')

        maxLength = maxLength > 0 ? maxLength.length + 10 : 99

        if (['.', '+', '-'].includes(e.key)) {
            e.preventDefault();
        }

        if (value.length > maxLength) {
            if (![8, 9, 37, 39, 46].includes(key)) {
                e.preventDefault();
            }
        }

    });


    $(".select2").select2({
        theme: "bootstrap-5",
        selectionCssClass: "select2--small",
        dropdownCssClass: "select2--small",
        language: {
            noResults: function () {
                return "Este termo não foi localizado.";
            },
        },
    });

    $(".select2mult").select2({
        theme: "bootstrap-5",
        width: $(this).data("width")
            ? $(this).data("width")
            : $(this).hasClass("w-100")
                ? "100%"
                : "style",
        placeholder: $(this).data("placeholder"),
        closeOnSelect: false,
        language: {
            noResults: function () {
                return "Este termo não foi localizado.";
            },
        },
    });

    $('body').on('shown.bs.modal', '.modal', function () {
        $(this).find('select').each(function () {
            var dropdownParent = $(document.body);

            if ($(this).closest('.modal-body').length !== 0) {
                dropdownParent = $(this).closest('.modal-body')
            }

            //let dataSelected = $(this).data('selected')

            $(this).select2({
                dropdownParent: dropdownParent,
                theme: "bootstrap-5",
                width: $(this).data("width")
                    ? $(this).data("width")
                    : $(this).hasClass("w-100")
                        ? "100%"
                        : "style",
                placeholder: $(this).data("placeholder"),
                closeOnSelect: true,
                language: {
                    noResults: function () {
                        return "Este termo não foi localizado.";
                    },
                },
            })
            /*
            if (dataSelected) {
                $(this).val(["jpeg", "jpg", "pdf", "doc", "docx"]).trigger("change");
            }
            */
        });
    });

    /**
     * Função excuteTrigger
     * Exibe os campos que devem ser exibidos através de uma trigger
     *
     */
    function excuteTrigger(arrTrigger, valueId) {
        arrTrigger.map(function (item) {
            if (item.parent_response_id == valueId) {
                //Exibir campo
                $("#control_field_" + item.field_id).removeClass("collapse");
            }
        });
    }

    /**
     * Busca por todos as classes dispatchTrigger e no carregamento da página
     * carrega os elementos conforme a trigger e campos já  com valores setados
     */
    $(".dispatchTrigger").each(function () {
        var controlValue = $(this).val();
        var triggers = $(this).data("triggers");

        /*
            Se for um campo do tipo select multiplo...
        */
        if ($(this).hasClass("select2mult")) {
            controlValue.map(function (x) {
                excuteTrigger(triggers, parseInt(x));
            });
        } else if ($(this).hasClass("select2")) {
            /*
                Ou do tipo Seleção única
            */
            excuteTrigger(triggers, controlValue);
        } else {
            /*
                Ou do tipo optativa única/múltipla
            */
            if (
                ($(this).attr("type") === "radio" || $(this).attr("type") === "checkbox") && $(this).prop("checked")) {
                excuteTrigger(triggers, controlValue);
            }
        }
    });



    /**
     * Dispara trigger (quando houver) no evento change dos campos
     */
    $(".dispatchTrigger").on("change", function () {

        var strTriggeredFields = $(this).data("triggeredfields").toString()

        var triggeredFields = strTriggeredFields.indexOf("|") !== -1
            ? strTriggeredFields.split('|')
            : [strTriggeredFields.toString()]


        // RETIRADO A PEDIDO DO ANALISTA - CARD "A Externa que tira a Interna" de 08/04/2024
        /*
        let elementName = ''

        console.log("strTriggeredFields: ", strTriggeredFields)

        triggeredFields.map(fieldId => {            
            $("#control_field_" + fieldId).addClass("collapse")
            elementName = "field_" + fieldId
            elementField = $("[name=" + elementName + "]")

            if (elementField.attr("checked") == false) {
                cleanElementForm(elementField)
            }            
        })*/        

        let triggers = $(this).data("triggers"); // Trigger do objeto data('triggers')

        let controlValue = $(this).val(); //valor do campo
        let controlName = $(this).attr("name"); // Nome do campo
        let controlIdField = $(this).attr("name").split("_")[1]; //Id do campo

        let myTrigger = triggers.filter(
            (item) => item.parent_field_id == controlIdField
        );
        /*
            Cria um  array onde serão inseridos todos os id de campos que serão exibidos
            caso o valor setado (checkado) bata com o valor que a trigger espera...
        */
        let arrAllMatches = [];

        let arrAllCleanFields = [];

        if (myTrigger.length > 0) {
            /* Percorre as triggers encontradas... */


            myTrigger.map(function (trigger, key) {
                /* Percorre todos os campos que tem o mesmo nome do campo clicado(input) */
                $("#control_field_" + trigger.field_id).addClass("collapse")
                $("#validFeedback_field_" + trigger.field_id).html('')

                $("[name='" + controlName + "']").each(function () {
                    const field = $(this)

                    const responses = Array.isArray(field.val())
                        ? field.val()
                        : [field.val()]                    
                    
                    responses.forEach(function (response, key) {
                        if (trigger.parent_response_id === parseInt(response)) {
                            if (field.prop('tagName') != 'INPUT') {                                
                                if (field.is(":selected")) {                                                                    
                                    arrAllMatches.push(trigger.field_id);                                    
                                }
                                arrAllMatches.push(trigger.field_id);
                            } else {
                                if (field.is(":checked")) {
                                    arrAllMatches.push(trigger.field_id)                                                                                                                                                
                                }
                            }
                        }
                    });
                });
            });
            
            arrAllMatches.map(function (item) {
                //exibe o campo alvo
                $("#control_field_" + item).removeClass("collapse");
            });

            // Limpando todos os campos que não serão exibidos
            myTrigger.forEach(function(trigger, tkey) {
                if (arrAllMatches.indexOf(trigger.field_id) == -1) {                    
                    cleanElementForm($('#field_'+trigger.field_id))
                }
            })
        }
    });

    // Contador de Textareas
    $("textarea.field").on('keyup', function (event) {
        const elementLength = $('#' + this.name + '_counter span')
        const currentLength = $(this).val().toString().length
        const maxLength = $(this).attr('maxlength')

        if (currentLength > maxLength) {
            $(this).val($(this).val().slice(0, maxLength));
        }

        elementLength.text(currentLength)
    })

    $("[data-tt=tooltip]").tooltip();

    $('a.disabled').css('cursor', 'notAllowed');
    $('a.disabled').css('opacity', '0.4');


    setTimeout(function () {
        $('.success_message').hide();
    }, 4000);


    // Reload na página "Estrutura do Formulário" quando o modal de cadastro de respostas for fechado.
    let fieldAnswersModal = document.querySelector('.fieldAnswersModal')    
    fieldAnswersModal.addEventListener('hidden.bs.modal', function (event) {
        window.location.reload()
    })


    // Reload na página "Estrutura do Formulário" quando o modal de cadastro de gatilhos for fechado.
    let fieldTriggersModal = document.querySelector('.fieldTriggersModal')    
    fieldTriggersModal.addEventListener('hidden.bs.modal', function (event) {
        window.location.reload()
    })
    
});
