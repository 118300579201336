window._ = require('lodash');

try {
    window.$ = window.jQuery = require('jquery');
    window.bootstrap = require('bootstrap');
    //window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');
    require('jquery-mask-plugin');
    require('select2')
    //require('bootstrap-fileinput')
    require('@yaireo/tagify')
    const Tagify = window.Tagify = require('@yaireo/tagify');
    const Swal = window.Swal = require('sweetalert2')
    const Toastify = window.Toastify = required('toastify-js');
    
} catch (e) { }

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */



window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';


/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });

//import Toastify from 'toastify-js'
window.Toastify = require('toastify-js');   
require('./toasts');
