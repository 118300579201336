function toast(msg, type){

    let backgroundColor = '';
    let borderColor = '';

    switch (type) {
        case 'success':  
            backgroundColor = '#d1e7dd'
            borderColor = '#1A8754'                   
            break;
        
        case 'info':  
            backgroundColor = '#cfe2ff'
            borderColor = '#236efd'                   
            break;
        
        case 'danger':
            backgroundColor = '#f7d7da'
            borderColor = '#dc3545'                   
            break;

        case 'warning':  
            backgroundColor = '#FEF3CD'
            borderColor = '#FCC107'                   
            break;

        default:
            break;
    }

    Toastify({
        text: msg+' ',                    
        newWindow: true,
        close: true,        
        gravity: "top",
        position: "right",
        style: {            
            background: backgroundColor,
            color: "#444444",
            font: "97% sans-serif",            
            borderRadius: "3px",
            padding: "18px",
            borderLeft: "5px solid "+borderColor

        },
        stopOnFocus: true,
        onClick: function(){}
    }).showToast();
}

window.toastSuccess = function toastSuccess(msg)
{    
    return toast(msg, 'success')
}

window.toastDanger = function toastDanger(msg)
{
    return toast(msg, 'danger')
}

window.toastInfo = function toastInfo(msg)
{
    return toast(msg, 'info')
}

window.toastWarning = function toastWarning(msg)
{
    return toast(msg, 'warning')
}